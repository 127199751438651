import * as React from 'react';
import { cn } from '@/utils/utils';
import Logo from '@/public/assets/logo.svg';
import { StepperMobile } from '@/components/stepper/mobile/StepperMobile';

enum MobileHeaderVariant {
  Transparent = 'Transparent',
  Black = 'Black'
}

interface Props {
  variant?: MobileHeaderVariant;
  guest?: boolean;
  orderStep?: number;
}

const MobileHeader: React.FC<Props> = ({ variant = MobileHeaderVariant.Black, guest = true, orderStep }) => {
  return (
    <header
      className={cn('h-[4.5rem]', 'w-full', 'px-6', 'flex', 'justify-between', 'items-center', {
        'bg-transparent': variant == MobileHeaderVariant.Transparent,
        'bg-common-black': variant == MobileHeaderVariant.Black
      })}
    >
      <a href="https://www.aneo.com/tjenester/mobility">
        <Logo
          className={cn({
            'fill-common-black': variant == MobileHeaderVariant.Transparent,
            'fill-yellow-100': variant == MobileHeaderVariant.Black
          })}
        />
      </a>
      {orderStep != undefined && <StepperMobile step={orderStep} />}
    </header>
  );
};

export { MobileHeader, MobileHeaderVariant };
