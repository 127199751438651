import * as React from 'react';
import { cn } from '@/utils/utils';
import { useEffect, useRef } from 'react';

interface Props {
  previousStep?: number;
  currentStep: number;
  children?: React.ReactNode;
  isAnimationRunning: boolean;
  setIsAnimationRunning: (state: boolean) => void;
}

const StepperContainerDesktop: React.FC<Props> = ({
  previousStep,
  currentStep,
  children,
  isAnimationRunning,
  setIsAnimationRunning
}) => {
  const steps = useRef<React.MutableRefObject<HTMLElement | null>[]>([]);

  useEffect(() => {
    if (previousStep != undefined && previousStep != currentStep) {
      setIsAnimationRunning(true);
      try {
        moveInner();
      } catch {
        resetInner();
      }
    }
  }, [currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  // https://medium.com/@dtinth/spring-animation-in-css-2039de6e1a03
  // f(0) = 0; f'(0) = 0; f''(t) = -100(f(t) - 1) - 15f'(t)
  // where 100 is the stiffness and 15 is the damping
  const spring = (t: number) => {
    return (
      -1.13389 * Math.pow(Math.E, -7.5 * t) * Math.sin(6.61438 * t) -
      Math.pow(Math.E, -7.5 * t) * Math.cos(6.61438 * t) +
      1
    );
  };

  // linear interpolation
  const lerp = (a: number, b: number, p: number) => {
    return a + p * (b - a);
  };

  const moveInner = () => {
    if (currentStep > steps.current.length - 1 || isAnimationRunning || previousStep == undefined) {
      return;
    }
    const currentStepRef = steps.current[previousStep].current;
    const nextStepRef = steps.current[currentStep].current;

    if (currentStepRef == undefined || nextStepRef == undefined || currentStepRef.children.length < 2) {
      return;
    }

    const inner = currentStepRef.children[0].children[0];

    const rect = currentStepRef.children[0].children[1];

    const newRect = nextStepRef.children[0].children[0];

    const oldDOMRect = rect.getBoundingClientRect();
    const newDOMRect = newRect.getBoundingClientRect();

    const centerOldDOMRect = {
      x: oldDOMRect.left + oldDOMRect.width / 2,
      y: oldDOMRect.top + oldDOMRect.height / 2
    };

    const centerNewDOMRect = {
      x: newDOMRect.left + newDOMRect.width / 2,
      y: newDOMRect.top + newDOMRect.height / 2
    };

    const innerHTML = inner as HTMLElement;

    innerHTML.style.transform = 'translate(0, 0)';

    const duration = 800; // duration of the animation in milliseconds
    let start: null | number; // start time of the animation

    const animateStep = (timestamp: number) => {
      if (!start) {
        start = timestamp;
      }
      let progress = timestamp - start;
      if (progress > duration) progress = duration;

      const x =
        centerNewDOMRect.x -
        centerOldDOMRect.x +
        lerp(centerOldDOMRect.x, centerNewDOMRect.x, spring(progress / 1000)) -
        centerNewDOMRect.x;
      const y =
        centerOldDOMRect.y -
        centerNewDOMRect.y +
        lerp(centerOldDOMRect.y, centerNewDOMRect.y, spring(progress / 1000)) -
        centerNewDOMRect.y;

      // TODO Fix the animation to not be reliant on this constant
      const HEIGHT_FIX_CONSTANT = 2.5;

      if (progress < duration) {
        innerHTML.style.transform = `translate(${x}px, ${y + HEIGHT_FIX_CONSTANT}px)`;
        window.requestAnimationFrame(animateStep);
      } else {
        innerHTML.style.transform = 'translate(0, 0)';

        nextStepRef.children[0].prepend(inner);
        setIsAnimationRunning(false);
      }
    };

    setIsAnimationRunning(true);
    window.requestAnimationFrame(animateStep);
  };

  const resetInner = () => {
    if (!Array.isArray(children) || currentStep > steps.current.length - 1) {
      return;
    }

    for (const child of steps.current) {
      const inner = child.current?.children[0]?.children[0];
      if (inner == undefined || (child.current?.children[0]?.children?.length ?? 0) < 2) {
        continue;
      }
      const stepRef = steps.current[currentStep].current;
      (inner as HTMLElement).style.transform = `translate(0px, 0px)`;
      stepRef?.children[0].prepend(inner);
      setIsAnimationRunning(false);
      break;
    }
  };

  return (
    <div
      className={cn(
        'select-none',
        'w-full',
        'flex-1',
        'grid',
        'grid-flow-col',
        'auto-cols-fr',
        'auto-rows-[1fr]',
        'items-stretch',
        'isolate'
      )}
    >
      {React.Children.map(children, (child, index) => {
        const childRef = React.createRef<HTMLElement>();
        steps.current[index] = childRef;

        // @ts-ignore
        return React.cloneElement(child, { ref: childRef });
      })}
    </div>
  );
};

const areEqual = (prev: Readonly<Props>, next: Readonly<Props>) => {
  let isEqual = prev.currentStep == next.currentStep;
  React.Children.forEach(prev.children, (prevChild: React.ReactNode, index) => {
    if (next.children == undefined) {
      return;
    }
    try {
      // @ts-ignore
      const nextChild = next.children[index] as React.ReactNode;
      if (!React.isValidElement(nextChild) || !React.isValidElement(prevChild)) {
        return;
      }
      if (prevChild?.props?.onClick !== nextChild?.props?.onClick) {
        isEqual = false;
      }
    } catch {}
  });
  return isEqual;
};

const MemoStepperContainerDesktop = React.memo(StepperContainerDesktop, areEqual);

export { MemoStepperContainerDesktop as StepperContainerDesktop };
