import * as React from 'react';
import { cn } from '@/utils/utils';
import StepperDesktopActive from '@/public/assets/illustrations/stepper-desktop-active.svg';
import StepperDesktopInactive from '@/public/assets/illustrations/stepper-desktop-inactive.svg';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Typography } from '@/components/Typography';
import { createRef } from 'react';

interface Props {
  label?: string;
  children?: React.ReactNode;
  isActive: boolean;
  onClick?: () => void;
}

const StepperStepDesktop = React.forwardRef<HTMLDivElement, Props>(({ label, children, isActive, onClick }, ref) => {
  const activeRef = createRef<HTMLDivElement>();
  const inactiveRef = createRef<HTMLDivElement>();

  const onStepClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      ref={ref}
      onClick={onStepClick}
      className={cn('flex-1', 'flex', 'gap-2', 'flex-col', 'items-center', 'justify-between')}
    >
      <div className={cn('flex', 'items-center', 'justify-center', 'relative', 'cursor-pointer')}>
        {children}
        <TransitionGroup className={cn('flex', 'items-center', 'justify-center', 'relative')}>
          {isActive && (
            <CSSTransition nodeRef={activeRef} classNames="fade" timeout={800}>
              <div ref={activeRef}>
                <StepperDesktopActive />
              </div>
            </CSSTransition>
          )}
          {!isActive && (
            <CSSTransition nodeRef={inactiveRef} classNames="fade" timeout={800}>
              <div ref={inactiveRef}>
                <StepperDesktopInactive />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
      <Typography
        type="XXS-H"
        component="span"
        className={cn('whitespace-nowrap', 'm-0', isActive ? 'text-common-black' : 'text-gray-80', 'cursor-pointer')}
      >
        {label}
      </Typography>
    </div>
  );
});

StepperStepDesktop.displayName = 'StepperStepDesktop';

export { StepperStepDesktop };
