import { atom } from 'jotai';
import { assemblyAtom } from '@/state/checkout/assembly';

export const _campaignAtom = atom<string | undefined>(undefined);
export const campaignAtom = atom(
  get => get(_campaignAtom),
  (get, set, newValue) => {
    const oldValue = get(_campaignAtom);
    const nextValue: string = typeof newValue === 'function' ? newValue(get(_campaignAtom)) : newValue;

    if (oldValue != nextValue) {
      set(assemblyAtom, undefined);
    }

    set(_campaignAtom, nextValue);
  }
);
