import { atom } from 'jotai';
import { StringOptionType } from '@/components/InputSelect';
import { assemblyAtom } from '@/state/checkout/assembly';
import { campaignAtom } from '@/state/checkout/campaign';
import { subscriptionAtom } from '@/state/checkout/subscription';

export interface LocationState {
  housingCompany: StringOptionType;
  chargingFacility: StringOptionType;
  parkingLot?: StringOptionType;
}

const _locationAtom = atom<LocationState | undefined>(undefined);
export const locationAtom = atom(
  get => get(_locationAtom),
  (get, set, newValue) => {
    const oldValue = get(_locationAtom);
    const nextValue: LocationState | undefined =
      typeof newValue === 'function' ? newValue(get(_locationAtom)) : newValue;

    if (
      oldValue?.housingCompany != nextValue?.housingCompany ||
      oldValue?.chargingFacility != nextValue?.chargingFacility
    ) {
      set(assemblyAtom, undefined);
      set(campaignAtom, undefined);
      set(subscriptionAtom, undefined);
    } else if (oldValue?.parkingLot != nextValue?.parkingLot) {
      set(assemblyAtom, undefined);
    }

    set(_locationAtom, nextValue);
  }
);
