import { atom } from 'jotai';

interface PersonaliaPerson {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
}

interface PersonaliaAddressState {
  address: string;
  zipcode: number;
  city: string;
}

export const personaliaPersonAtom = atom<PersonaliaPerson | undefined>(undefined);
export const personaliaAddressAtom = atom<PersonaliaAddressState | undefined>(undefined);
