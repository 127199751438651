import React from 'react';
import { cn } from '@/utils/utils';
import Checkmark from '@/public/assets/icons/checkmark.svg';
import locale from '@/public/locales/nb/checkout.json';
import { Typography } from '@/components/Typography';

const CheckoutFooter: React.FC = () => (
  <div className={cn('py-16', 'bg-green-100', 'flex', 'items-center', 'justify-center')}>
    <Typography component="h4" className={cn('flex', 'flex-col', 'gap-4', 'mr-16')}>
      <Typography type="XL-H" component="span" weight="bold" className={cn('flex', 'flex-col')}>
        <span className={cn('font-slant-pos30')}>{locale.footer.left.part1}</span>{' '}
        <span className={cn('font-slant-pos30')}>{locale.footer.left.part2}</span>
      </Typography>
      <Typography type="L-H" component="span" weight="light">
        {locale.footer.left.part3}
      </Typography>
    </Typography>
    <div className={cn('flex', 'flex-col', 'gap-5', 'max-w-lg')}>
      <div className={cn('flex', 'items-center')}>
        <Checkmark className={cn('mr-4', '[&>path]:stroke-green-25')} />
        <Typography type="L-B" component="p" className={cn('flex-1')}>
          {locale.footer.right.list.row1}
        </Typography>
      </div>
      <div className={cn('flex', 'items-center')}>
        <Checkmark className={cn('mr-4', '[&>path]:stroke-green-25')} />
        <Typography type="L-B" component="p" className={cn('flex-1')}>
          {locale.footer.right.list.row2}
        </Typography>
      </div>
      <div className={cn('flex', 'items-center')}>
        <Checkmark className={cn('mr-4', '[&>path]:stroke-green-25')} />
        <Typography type="L-B" component="p" className={cn('flex-1')}>
          {locale.footer.right.list.row3}
        </Typography>
      </div>
      <div className={cn('flex', 'items-center')}>
        <Checkmark className={cn('mr-4', '[&>path]:stroke-green-25')} />
        <Typography type="L-B" component="p" className={cn('flex-1')}>
          {locale.footer.right.list.row4}
        </Typography>
      </div>
      <div className={cn('flex', 'items-center')}>
        <Checkmark className={cn('mr-4', '[&>path]:stroke-green-25')} />
        <Typography type="L-B" component="p" className={cn('flex-1')}>
          {locale.footer.right.list.row5}
        </Typography>
      </div>
      <div className={cn('flex', 'items-center')}>
        <Checkmark className={cn('mr-4', '[&>path]:stroke-green-25')} />
        <Typography type="L-B" component="p" className={cn('flex-1')}>
          {locale.footer.right.list.row6}
        </Typography>
      </div>
    </div>
  </div>
);

export { CheckoutFooter };
