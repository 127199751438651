import * as React from 'react';
import { useEffect, useState } from 'react';
import { campaignIdToEstablishmentType, cn, isChargingSupplierWithBackplate } from '@/utils/utils';
import { StepperDesktop } from '@/components/stepper/desktop/StepperDesktop';
import tailwindConfig from '@/tailwind.config';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import { DesktopHeader, DesktopHeaderVariant } from '@/components/header/DesktopHeader';
import { MobileHeader, MobileHeaderVariant } from '@/components/header/MobileHeader';
import { fetcher as campaignFetcher, getQueryKey } from '@/utils/hooks/useCampaigns';
import { fetcher as parkingLotFetcher, getQueryKey as getParkingLotQueryKey } from '@/utils/hooks/useParkingLot';
import {
  fetcher as chargingFacilityFetcher,
  getQueryKey as getChargingFacilityQueryKey
} from '@/utils/hooks/useChargingFacility';
import { useQueryClient } from '@tanstack/react-query';
import { EstablishmentTypeEnum, ProductDTO } from '@/utils/api/services/openapi';
import { useAtomValue } from 'jotai/index';
import { locationAtom } from '@/state/checkout/location';
import { CheckoutFooter } from '@/components/CheckoutFooter';
import { campaignAtom } from '@/state/checkout/campaign';
import { subscriptionAtom } from '@/state/checkout/subscription';
import { personaliaAddressAtom, personaliaPersonAtom } from '@/state/checkout/personalia';
import { assemblyAtom } from '@/state/checkout/assembly';
import { useAtomCallback } from 'jotai/react/utils';
import { useLeavePageConfirmation } from '@/utils/hooks/useLeavePageConfirmation';
import Head from 'next/head';
import { useLogger } from '@/utils/logger';
import {
  fetcher as chargersByParkingLotFetcher,
  getQueryKey as chargersByParkingLotQueryKey
} from '@/utils/hooks/useChargersByParkingLot';

// Having this outer Layout and InnerLayout is needed to stop flickering without getting hydration error
// while also preserving the animation in the stepper. As the stepper is reliant on the change of the step
// to properly animate the initial change if the users starts at not the first page of the stepper
const CheckoutLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // @ts-ignore
  const { screens } = tailwindConfig.theme;
  const [isSmall, setIsSmall] = useState<boolean>();
  const isSmallQuery = useMediaQuery({
    query: `only screen and (max-width: ${screens.md.max})`
  });

  // This is needed as if we use isSmallQuery directly in the code then NextJS bitches about hydration error
  useEffect(() => {
    setIsSmall(isSmallQuery);
  }, [isSmallQuery]);

  if (isSmall == undefined) {
    return <></>;
  }

  return <InnerLayout page={children} isSmall={isSmall} />;
};

export interface CheckoutProps {
  isSmall: boolean;
  next: () => Promise<void>;
  previous: () => Promise<void>;
}

interface InnerLayoutProps {
  isSmall: boolean;
  page: React.ReactNode;
}

const InnerLayout: React.FC<InnerLayoutProps> = ({ page, isSmall }) => {
  const router = useRouter();
  const logger = useLogger();

  const routerPush = async (path: string) => {
    if (router.pathname == path) {
      return;
    }
    await router.push(path);
  };

  const queryClient = useQueryClient();
  // State
  const location = useAtomValue(locationAtom);
  const campaign = useAtomCallback(async (get, set, _campaign?: string) => {
    if (_campaign) {
      await set(campaignAtom, _campaign);
    }
    return get(campaignAtom);
  });
  const subscription = useAtomValue(subscriptionAtom);
  const personInfo = useAtomValue(personaliaPersonAtom);
  const addressInfo = useAtomValue(personaliaAddressAtom);
  const assembly = useAtomValue(assemblyAtom);

  const [step, setStep] = useState(0);

  useEffect(() => {
    const paths = router.pathname.split('/');
    const last = paths[paths.length - 1];
    switch (last) {
      case 'housing-company': {
        navigate(0);
        break;
      }
      case 'campaign': {
        navigate(1);
        break;
      }
      case 'subscription': {
        navigate(2);
        break;
      }
      case 'person': {
        navigate(3);
        break;
      }
      case 'address': {
        navigate(4);
        break;
      }
      case 'assembly': {
        navigate(5);
        break;
      }
      case 'confirm': {
        navigate(6);
        break;
      }
    }
  }, [router.pathname]);

  const getCampaigns = async (chargingFacilityId?: string, parkingLotId?: string) => {
    let campaigns: ProductDTO[] = [];
    if (chargingFacilityId) {
      const queryKey = getQueryKey(chargingFacilityId, undefined, parkingLotId);
      campaigns = await queryClient.fetchQuery({
        queryKey,
        queryFn: () => campaignFetcher(chargingFacilityId, undefined, parkingLotId, logger)
      });
    }

    return campaigns;
  };

  const getParkingLot = async (parkingLotId?: string) => {
    let parkingLot;
    if (parkingLotId) {
      const queryKey = getParkingLotQueryKey(parkingLotId);
      parkingLot = await queryClient.fetchQuery({
        queryKey,
        queryFn: () => parkingLotFetcher(parkingLotId)
      });
    }

    return parkingLot;
  };

  const parkingLotHasCharger = async (parkingLotId?: string): Promise<boolean> => {
    if (parkingLotId == null) {
      return false;
    }
    const result = await queryClient.fetchQuery({
      queryKey: chargersByParkingLotQueryKey(parkingLotId),
      queryFn: () => chargersByParkingLotFetcher(parkingLotId)
    });
    return result.length > 0;
  };

  const getChargingFacility = async (chargingFacilityId?: string) => {
    let chargingFacility;
    if (chargingFacilityId) {
      const queryKey = getChargingFacilityQueryKey(chargingFacilityId);
      chargingFacility = await queryClient.fetchQuery({
        queryKey,
        queryFn: () => chargingFacilityFetcher(chargingFacilityId)
      });
    }
    return chargingFacility;
  };

  const isValidState = async (navigationStep: number) => {
    switch (navigationStep) {
      case 6: {
        const chargingFacility = await getChargingFacility(location?.chargingFacility.value.toString());
        let parkingLot;
        let hasCharger = false;
        if (chargingFacility?.fixedParking && location?.parkingLot) {
          parkingLot = await getParkingLot(location.parkingLot.value.toString());
          hasCharger = await parkingLotHasCharger(location.parkingLot.value);
        }

        const establishmentType = campaignIdToEstablishmentType(await campaign(), logger);

        if (
          ((parkingLot == undefined && chargingFacility?.fixedParking) ||
            (parkingLot != undefined && !chargingFacility?.fixedParking) ||
            parkingLot?.backplatePresent) &&
          (chargingFacility == undefined || isChargingSupplierWithBackplate(chargingFacility?.chargingSupplier)) &&
          assembly == undefined &&
          !hasCharger &&
          (establishmentType == EstablishmentTypeEnum._1 || establishmentType == EstablishmentTypeEnum._3)
        ) {
          return false;
        }
      }
      case 5: {
        if (addressInfo == undefined) {
          return false;
        }
      }
      case 4: {
        if (personInfo == undefined) {
          return false;
        }
      }
      case 3: {
        const establishmentType = campaignIdToEstablishmentType(await campaign(), logger);
        if (
          subscription == undefined &&
          (establishmentType == EstablishmentTypeEnum._1 || establishmentType == EstablishmentTypeEnum._3)
        ) {
          return false;
        }
      }
      case 2: {
        const campaigns = await getCampaigns(
          location?.chargingFacility.value.toString(),
          location?.parkingLot?.value.toString()
        );
        if (location == undefined || (campaigns.length > 1 && (await campaign()) == undefined)) {
          return false;
        }
      }
      case 1: {
        if (location == undefined) {
          return false;
        }
      }
      case 0: {
        break;
      }
    }
    return true;
  };

  const navigate = async (navigationStep: number, forward: boolean = true) => {
    const validState = await isValidState(navigationStep);
    switch (navigationStep) {
      case 0: {
        setStep(0);
        await routerPush('/checkout/housing-company');
        break;
      }
      case 1: {
        if (!validState) {
          await navigate(navigationStep - 1, false);
          break;
        }

        // Routing
        const campaigns = await getCampaigns(
          location?.chargingFacility.value.toString(),
          location?.parkingLot?.value.toString()
        );

        if (campaigns.length > 1) {
          setStep(1);
          await routerPush('/checkout/campaign');
        } else {
          if (campaigns.length == 1 && campaigns[0].id) {
            await campaign(campaigns[0].id);
          }

          if (forward) {
            await navigate(navigationStep + 1, true);
          } else {
            await navigate(navigationStep - 1, false);
          }
        }
        break;
      }
      case 2: {
        // State Guard
        if (!validState) {
          await navigate(navigationStep - 1, false);
          break;
        }

        const establishmentType = campaignIdToEstablishmentType(await campaign(), logger);

        // Routing
        if (establishmentType == EstablishmentTypeEnum._1 || establishmentType == EstablishmentTypeEnum._3) {
          setStep(1);
          await routerPush('/checkout/subscription');
        } else if (forward) {
          await navigate(navigationStep + 1, true);
        } else {
          await navigate(navigationStep - 1, false);
        }
        break;
      }
      case 3: {
        // State Guard
        if (!validState) {
          await navigate(navigationStep - 1, false);
          break;
        }

        // Routing
        setStep(2);
        await routerPush('/checkout/personalia/person');
        break;
      }
      case 4: {
        // State Guard
        if (!validState) {
          await navigate(navigationStep - 1, false);
          break;
        }

        // Routing
        setStep(2);
        await routerPush('/checkout/personalia/address');
        break;
      }
      case 5: {
        // State Guard
        if (!validState) {
          await navigate(navigationStep - 1, false);
          break;
        }

        // Routing
        const chargingFacility = await getChargingFacility(location?.chargingFacility.value.toString());
        let parkingLot;
        let hasCharger = false;
        if (chargingFacility?.fixedParking) {
          parkingLot = await getParkingLot(location?.parkingLot?.value.toString());
          hasCharger = await parkingLotHasCharger(location?.parkingLot?.value);
        }

        const establishmentType = campaignIdToEstablishmentType(await campaign(), logger);

        if (
          parkingLot?.backplatePresent &&
          isChargingSupplierWithBackplate(chargingFacility?.chargingSupplier) &&
          !hasCharger &&
          (establishmentType == EstablishmentTypeEnum._1 || establishmentType == EstablishmentTypeEnum._3)
        ) {
          setStep(3);
          await routerPush('/checkout/assembly');
        } else if (forward) {
          await navigate(navigationStep + 1, true);
        } else {
          await navigate(navigationStep - 1, false);
        }
        break;
      }
      case 6: {
        if (!validState) {
          await navigate(navigationStep - 1, false);
          break;
        }

        setStep(4);
        await routerPush('/checkout/confirm');
        break;
      }
    }
  };

  const next = async () => {
    const paths = router.pathname.split('/');
    const last = paths[paths.length - 1];
    switch (last) {
      case 'housing-company': {
        await navigate(1);
        break;
      }
      case 'campaign': {
        await navigate(2);
        break;
      }
      case 'subscription': {
        await navigate(3);
        break;
      }
      case 'person': {
        await navigate(4);
        break;
      }
      case 'address': {
        await navigate(5);
        break;
      }
      case 'assembly': {
        await navigate(6);
        break;
      }
    }
  };

  const previous = async () => {
    const paths = router.pathname.split('/');
    const last = paths[paths.length - 1];
    switch (last) {
      case 'campaign': {
        await navigate(0, false);
        break;
      }
      case 'subscription': {
        await navigate(1, false);
        break;
      }
      case 'person': {
        await navigate(2, false);
        break;
      }
      case 'address': {
        await navigate(3, false);
        break;
      }
      case 'assembly': {
        await navigate(4, false);
        break;
      }
      case 'confirm': {
        await navigate(5, false);
        break;
      }
    }
  };

  useLeavePageConfirmation(
    true,
    '/checkout',
    ['https://ecom.externalintegration.payex.com', 'https://psp-ecommerce.payex.com/'],
    'Dersom du går videre avbryter du bestillingen.'
  );

  return (
    <>
      <Head>
        <title>Aneo Mobility - Bestill</title>
      </Head>
      <div className={cn('flex', 'flex-col', 'min-h-screen')}>
        <div className={cn({ hidden: isSmall })}>
          <DesktopHeader variant={DesktopHeaderVariant.Transparent} />
        </div>
        <div className={cn({ hidden: !isSmall })}>
          <MobileHeader orderStep={step} variant={MobileHeaderVariant.Transparent} />
        </div>
        <main
          className={cn(
            'w-full',
            'justify-center',
            'flex',
            'pt-4',
            'px-4',
            'md:pt-6',
            'md:px-6',
            'h-inherit',
            isSmall ? 'min-h-[calc(100vh-4.5rem)]' : 'min-h-[calc(100vh-5.5rem)]'
          )}
        >
          <div className={cn('w-md', 'md:w-full', 'flex', 'flex-col')}>
            <div className={cn({ hidden: isSmall })}>
              <StepperDesktop step={step} navigate={navigate} />
            </div>
            <div className={cn('h-full', 'flex', 'my-20', 'md:my-6')}>
              {!React.isValidElement(page)
                ? page
                : React.cloneElement(page as React.ReactElement<CheckoutProps>, { isSmall: isSmall, next, previous })}
            </div>
          </div>
        </main>
        {!isSmall && step >= 0 && step < 3 && <CheckoutFooter />}
      </div>
    </>
  );
};

export { CheckoutLayout };
