import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PricingService, ProductDTO } from '@/utils/api/services/openapi';
import { LoggingContextType, useLogger } from '@/utils/logger';

const campaignsDefaultQueryKey = 'getEstablishmentProducts';

const fetcher = async (
  chargingFacilityId?: string,
  discountCode?: string,
  parkingLotId?: string,
  logger?: LoggingContextType
) => {
  if (chargingFacilityId == undefined || chargingFacilityId == '') {
    return [];
  }
  const oneTimePricings = await PricingService.getOneTimePricings(parkingLotId, chargingFacilityId, discountCode);
  if (oneTimePricings.establishmentProducts == undefined || oneTimePricings.establishmentProducts.length == 0) {
    logger
      ?.addContext({
        ChargingFacility: chargingFacilityId,
        ParkingLot: parkingLotId,
        DiscountCode: discountCode
      })
      .warn(
        'establishment products were undefined or empty for charging facility {ChargingFacility}',
        chargingFacilityId
      );
  }
  return oneTimePricings.establishmentProducts ?? [];
};

const getQueryKey = (chargingFacilityId?: string, discountCode?: string, parkingLotId?: string) => {
  return [campaignsDefaultQueryKey, chargingFacilityId, discountCode, parkingLotId];
};

const useCampaigns = (
  chargingFacilityId?: string,
  discountCode?: string,
  parkingLotId?: string,
  options: Omit<UseQueryOptions<ProductDTO[], unknown, ProductDTO[], QueryKey>, 'initialData'> = {}
): QueryObserverResult<ProductDTO[]> => {
  const logger = useLogger();

  const query = useQuery<ProductDTO[]>({
    queryKey: getQueryKey(chargingFacilityId, discountCode, parkingLotId),
    queryFn: () => fetcher(chargingFacilityId, discountCode, parkingLotId, logger),
    ...options
  });

  return { ...query, isLoading: query.isLoading && options.enabled !== false } as QueryObserverResult<ProductDTO[]>;
};

export { useCampaigns, getQueryKey, fetcher };
