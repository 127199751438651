import * as React from 'react';
import { cn } from '@/utils/utils';
import { StepperContainerDesktop } from '@/components/stepper/desktop/StepperContainerDesktop';
import { StepperStepDesktop } from '@/components/stepper/desktop/StepperStepDesktop';
import { StepperActiveIndicatorDesktop } from '@/components/stepper/desktop/StepperActiveIndicatorDesktop';
import { useEffect, useRef, useState } from 'react';
import usePrevious from '@/utils/hooks/usePrevious';
import locale from '@/public/locales/nb/checkout.json';

interface Props {
  step: number;
  navigate?: (navigationStep: number, forward?: boolean) => void;
}

type QueuedCall = {
  fn: () => void;
};

const StepperDesktop: React.FC<Props> = ({ step, navigate }) => {
  const [currentStep, setCurrentStep] = useState(step);
  const prevStep = usePrevious(currentStep);
  const [isAnimationRunning, setIsAnimationRunning] = useState(false);
  const [queuedCalls, setQueuedCalls] = useState<QueuedCall[]>([]);

  const setAnimation = (state: boolean) => {
    setIsAnimationRunning(state);
  };

  useEffect(() => {
    if (isAnimationRunning || queuedCalls.length == 0) {
      return;
    }

    const call = queuedCalls[0];

    call.fn();
    setQueuedCalls([...queuedCalls.slice(1)]);
  }, [isAnimationRunning, queuedCalls]);

  useEffect(() => {
    const queuedCall: QueuedCall = {
      fn: () => {
        setCurrentStep(step);
      }
    };
    // TODO: TEMP FIX TO STOP RACE CONDITION
    setTimeout(() => {
      setQueuedCalls(queuedCalls => [...queuedCalls, queuedCall]);
    }, 200);
  }, [step]);

  return (
    <div className={cn()}>
      <StepperContainerDesktop
        isAnimationRunning={isAnimationRunning}
        setIsAnimationRunning={setAnimation}
        previousStep={prevStep}
        currentStep={currentStep}
      >
        <StepperStepDesktop
          onClick={React.useCallback(() => navigate && navigate(0, currentStep < 0), [navigate, currentStep])}
          isActive={currentStep == 0}
          label={locale.stepper.step1}
        >
          <StepperActiveIndicatorDesktop />
        </StepperStepDesktop>
        <StepperStepDesktop
          // Setting forward to true as if campaign is less than 2 then we want to move forward to the sub selection
          onClick={React.useCallback(() => navigate && navigate(1, true), [navigate])}
          isActive={currentStep == 1}
          label={locale.stepper.step2}
        ></StepperStepDesktop>
        <StepperStepDesktop
          onClick={React.useCallback(() => navigate && navigate(3, currentStep < 3), [navigate, currentStep])}
          isActive={currentStep == 2}
          label={locale.stepper.step3}
        ></StepperStepDesktop>
        <StepperStepDesktop
          onClick={React.useCallback(() => navigate && navigate(5, currentStep < 5), [navigate, currentStep])}
          isActive={currentStep == 3}
          label={locale.stepper.step4}
        ></StepperStepDesktop>
        <StepperStepDesktop
          onClick={React.useCallback(() => navigate && navigate(6, currentStep < 6), [navigate, currentStep])}
          isActive={currentStep == 4}
          label={locale.stepper.step5}
        ></StepperStepDesktop>
      </StepperContainerDesktop>
    </div>
  );
};

export { StepperDesktop };
