import * as React from 'react';
import { cn } from '@/utils/utils';
import { StepperCircleMobile } from '@/components/stepper/mobile/StepperCircleMobile';
import { Typography } from '@/components/Typography';

interface Props {
  step: number;
  steps: string[];
}

const StepperContainerMobile: React.FC<Props> = ({ step, steps }) => (
  <div className={cn('select-none', 'flex', 'items-center', 'justify-end', 'gap-[12px]', 'text-gray-100')}>
    <Typography type="XXS-H" component="span">
      {steps[step]}
    </Typography>
    <StepperCircleMobile currentStep={step} numberOfSteps={steps.length} radius={50} />
  </div>
);

export { StepperContainerMobile };
