import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ChargingFacilityDTO, ChargingFacilityService } from '@/utils/api/services/openapi';

const chargingFacilityDefaultQueryKey = 'getChargingFacility';

const fetcher = (chargingFacilityId: string) => {
  return ChargingFacilityService.getChargingFacilityById(chargingFacilityId);
};

const getQueryKey = (chargingFacilityId: string) => {
  return [chargingFacilityDefaultQueryKey, chargingFacilityId];
};

const useChargingFacility = (
  chargingFacilityId: string,
  options: Omit<UseQueryOptions<ChargingFacilityDTO, unknown, ChargingFacilityDTO, QueryKey>, 'initialData'> = {}
): QueryObserverResult<ChargingFacilityDTO> => {
  const query = useQuery<ChargingFacilityDTO>({
    queryKey: getQueryKey(chargingFacilityId),
    queryFn: () => fetcher(chargingFacilityId),
    ...options
  });

  return {
    ...query,
    isLoading: query.isLoading && options.enabled !== false
  } as QueryObserverResult<ChargingFacilityDTO>;
};

export { useChargingFacility, fetcher, getQueryKey };
