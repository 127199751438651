import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ChargingPointService, SChargingPointDTO } from '@/utils/api/services/openapi';

const chargersByParkingLotDefaultQueryKey = 'getChargersByParkingLotId';

const fetcher = async (parkingLotId: string) => {
  if (parkingLotId == undefined || parkingLotId == '') {
    return [];
  }
  return await ChargingPointService.getChargersByParkingLotId(parkingLotId);
};

const getQueryKey = (parkingLotId: string) => [chargersByParkingLotDefaultQueryKey, parkingLotId];

const useChargersByParkingLot = (
  parkingLotId: string,
  options: Omit<UseQueryOptions<SChargingPointDTO[], unknown, SChargingPointDTO[], QueryKey>, 'initialData'> = {}
): QueryObserverResult<SChargingPointDTO[]> => {
  const query = useQuery<SChargingPointDTO[]>({
    queryKey: getQueryKey(parkingLotId),
    queryFn: () => fetcher(parkingLotId),
    ...options
  });

  return { ...query, isLoading: query.isLoading && options.enabled !== false } as QueryObserverResult<
    SChargingPointDTO[]
  >;
};

export { useChargersByParkingLot, fetcher, getQueryKey };
