import * as React from 'react';
import { cn } from '@/utils/utils';
import ActiveIndicator from '@/public/assets/illustrations/stepper-desktop-active-car.svg';

interface Props {}

const StepperActiveIndicatorDesktop: React.FC<Props> = ({}) => {
  return (
    <div className={cn('z-[1]', 'absolute', 'flex', 'pointer-events-none')}>
      <ActiveIndicator />
    </div>
  );
};

export { StepperActiveIndicatorDesktop };
