import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ParkingLotDTO, ParkingLotService } from '@/utils/api/services/openapi';

const parkingLotDefaultQueryKey = 'getParkingLot';

const fetcher = (parkingLotId: string) => {
  return ParkingLotService.getParkingLotById(parkingLotId);
};

const getQueryKey = (parkingLotId: string) => {
  return [parkingLotDefaultQueryKey, parkingLotId];
};

const useParkingLot = (
  parkingLotId: string,
  options: Omit<UseQueryOptions<ParkingLotDTO, unknown, ParkingLotDTO, QueryKey>, 'initialData'> = {}
): QueryObserverResult<ParkingLotDTO> => {
  const query = useQuery<ParkingLotDTO>({
    queryKey: getQueryKey(parkingLotId),
    queryFn: () => fetcher(parkingLotId),
    ...options
  });

  return { ...query, isLoading: query.isLoading && options.enabled !== false } as QueryObserverResult<ParkingLotDTO>;
};

export { useParkingLot, fetcher, getQueryKey };
