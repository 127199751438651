import SingletonRouter, { Router } from 'next/router';
import { useEffect } from 'react';

const defaultConfirmationDialog = async (msg: string) => window.confirm(msg);

export const useLeavePageConfirmation = (
  shouldPreventLeaving: boolean,
  activePath: string,
  whitelistedDomains: Array<string> = [],
  message: string = 'Dersom du går videre vil du miste ulagret data.',
  confirmationDialog: (msg: string) => Promise<boolean> = defaultConfirmationDialog
) => {
  activePath = SingletonRouter.router?.basePath + activePath;
  whitelistedDomains = whitelistedDomains.map(domain => new URL(domain).origin);

  useEffect(() => {
    // @ts-ignore
    if (!SingletonRouter.router?.change) {
      return;
    }

    // @ts-ignore
    const originalChangeFunction = SingletonRouter.router.change;
    const originalOnBeforeUnloadFunction = window.onbeforeunload;

    /*
     * Modifying the window.onbeforeunload event stops the browser tab/window from
     * being closed or refreshed. Since it is not possible to alter the close or reload
     * alert message, an empty string is passed to trigger the alert and avoid confusion
     * about the option to modify the message.
     */
    if (shouldPreventLeaving) {
      window.onbeforeunload = () => '';
    } else {
      window.onbeforeunload = originalOnBeforeUnloadFunction;
    }

    /*
     * Overriding the router.change function blocks Next.js route navigations
     * and disables the browser's back and forward buttons. This opens up the
     * possibility to use the window.confirm alert instead.
     */
    if (shouldPreventLeaving) {
      // @ts-ignore
      SingletonRouter.router.change = async (...args) => {
        const [historyMethod, , as] = args;
        // @ts-ignore
        const currentUrl = SingletonRouter.router?.basePath + SingletonRouter.router?.state.asPath.split('?')[0];
        const changedUrl = as.split('?')[0];

        try {
          const changeURL = new URL(as);

          if (-1 < whitelistedDomains.findIndex(origin => origin === changeURL.origin)) {
            window.onbeforeunload = originalOnBeforeUnloadFunction;
            // @ts-ignore
            Router.prototype.change.apply(SingletonRouter.router, args);
            return;
          }
        } catch {}

        if (changedUrl.startsWith(activePath)) {
          // @ts-ignore
          Router.prototype.change.apply(SingletonRouter.router, args);
          return;
        }

        const hasNavigatedAwayFromPage = currentUrl !== changedUrl;
        const wasBackOrForwardBrowserButtonClicked = historyMethod === 'replaceState';
        let confirmed = false;

        if (hasNavigatedAwayFromPage) {
          confirmed = await confirmationDialog(message);
        }

        if (confirmed) {
          // @ts-ignore
          Router.prototype.change.apply(SingletonRouter.router, args);
        } else if (wasBackOrForwardBrowserButtonClicked && hasNavigatedAwayFromPage) {
          /*
           * The URL changes even if the user clicks "false" to navigate away from the page.
           * It is necessary to update it to reflect the current URL.
           */
          // @ts-ignore
          await SingletonRouter.router?.push(SingletonRouter.router?.state.asPath);
        }
      };
    }

    /*
     * When the component is unmounted, the original change function is assigned back.
     */
    return () => {
      // @ts-ignore
      SingletonRouter.router.change = originalChangeFunction;
      window.onbeforeunload = originalOnBeforeUnloadFunction;
    };
  }, [shouldPreventLeaving, message, confirmationDialog]);
};
