import * as React from 'react';
import { StepperContainerMobile } from '@/components/stepper/mobile/StepperContainerMobile';
import locale from '@/public/locales/nb/checkout.json';

interface Props {
  step: number;
}

const StepperMobile: React.FC<Props> = ({ step }) => (
  <StepperContainerMobile
    steps={[
      locale.stepper.step1,
      locale.stepper.step2,
      locale.stepper.step3,
      locale.stepper.step4,
      locale.stepper.step5
    ]}
    step={step}
  />
);

export { StepperMobile };
